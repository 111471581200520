import React, { useContext } from 'react';
import Context from '../Context/Context';
import HappyprancerPaypalHybrid from '../Components/Subscription/HappyprancerPaypalHybrid';
import InstitutionContext from '../Context/InstitutionContext';
import { useNavigate } from 'react-router-dom';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Home/Footer';

const HybridPayment = () => {
  const { institutionData: InstitutionData } = useContext(InstitutionContext);
  const { isAuth, productList, userData: UserCtx } = useContext(Context);
  const Navigate = useNavigate();

  const hybridProduct = productList.find(item => item.subscriptionType === 'Hybrid');

  const paymentHandler = (item) => {
    if (isAuth) {
      if (UserCtx?.status === 'Active' && UserCtx?.productIds?.some(productId => productId === item.productId)) {
        return (
          <p
            className="text-[1rem] w-[15rem] px-12 py-2 rounded-2xl border-[0.2rem] h-[3rem] flex justify-center items-center"
            style={{
              color: InstitutionData.LightPrimaryColor,
              borderColor: InstitutionData.LightPrimaryColor
            }}
          >
            Subscribed
          </p>
        );
      } else {
        if (item.currency === 'USD' && item.subscriptionType === 'Hybrid') {
          return <HappyprancerPaypalHybrid />;
        }
      }
    } else {
      return (
        <button
          onClick={() => {
            Navigate('/signup');
          }}
          className="w-[15rem] px-12 py-2 rounded-2xl hover:text-lightPrimaryColor hover:bg- hover:border-lightPrimaryColor hover:border-[0.3rem] h-[3rem] flex justify-center items-center mt-auto mb-10 text-white"
          style={{
            backgroundColor: InstitutionData.LightPrimaryColor
          }}
        >
          Sign Up
        </button>
      );
    }
  };

  return (
    <>
    <NavBar />
      <div className="relative flex place-items-center h-screen text-white lg:w-3/4 md:w-full sm:w-full "
        style={{
          backgroundColor: InstitutionData.LightPrimaryColor
        }}
      >
        
        <div className="flex flex-col mb-40 lg:flex-row justify-start p-8">
          <div className='class-details flex-grow lg:block'>
            <h1 className="text-4xl font-sans text-left text-white font-medium lg:mt-[14rem]">HYBRID CLASS</h1>
            <h2 className="font-light mb-4 max-w-2xl">
              Details about the hybrid class all info 
            </h2>
            <p className="max-w-2xl mb-8">
              Start your journey towards the fitness dance through happy prancer new online fitness dance class
            </p>
            <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-500 mb-8">
              Get Started
            </button>
          </div>
        </div>
        
        {hybridProduct && (
          <li
            key={hybridProduct.productId}
            className="subscription-card w-72 sm:w-full lg:w-[32%] lg:px-8 rounded-[2rem] z-10 flex flex-col items-center gap-4 shadowSubscribe bg-white border-[0.1rem] absolute lg:relative top-[60%]  left-1/2 transform -translate-x-1/2 lg:transform-none lg:top-[6%] lg:left-[20%] lg:h-[74%] "
            style={{
              borderColor: InstitutionData.LightPrimaryColor,
            }}
          >
            <p className="text-[1.6rem] mt-4 font-bold text-center text-black">{hybridProduct.heading}</p>
            <ul className="text-[1rem] pl-0 flex flex-col items-center ">
              {hybridProduct.provides.map((provide, j) => (
                <li key={`${hybridProduct.productId}-provide-${j}`} className="text-center text-black">
                  <p>{provide}</p>
                </li>
              ))}
            </ul>
            <div className="flex-grow"></div>
            <h1 className="w-[100%] text-center text-[2.3rem] font-bold mt-[-3rem]">
              {(hybridProduct.currency === 'INR' ? '₹ ' : '$ ') +
                parseInt(hybridProduct.amount) / 100 +
                '/' +
                hybridProduct.durationText}
            </h1>
            <div className="z-1 flex justify-center items-center ">
              {paymentHandler(hybridProduct)}
            </div>
          </li>
        )}
      </div>
      <div className='mt-[15rem] sm:mt-30 lg:mt-0'>
      <Footer  />
      </div>
      
    </>
  );
};

export default HybridPayment;
