import React, { useState, useEffect, useContext } from 'react'
import { API, Storage } from 'aws-amplify'
import dancebg from '../Utils/images/dancebg.jpg'
import './Gallery.css'
import web from '../Utils/Data/data.json'
// import { ReactSortable } from "react-sortablejs";
import Spinner from '../spinner'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Home/Footer'
import Context from '../Context/Context'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { toast } from 'react-toastify'

const Gallery = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState();
  const [img, setImg] = useState();
  const [model, setModel] = useState(false);
  const [showInput, setShowInput] = useState(false);
  //eslint-disable-next-line
  const [uploadingImage, setUploadingImage] = useState(false);
  const userData = useContext(Context);
  const user = userData.userData;
  const [selectedFile, setSelectedFile] = useState("");
  const [reloadGallery, setReloadGallery] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const bucketName = 'team-dev-testing';
  const region = 'us-east-2';
  const institution = user.institution;

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log("File selected:", file);
  
    // Check if the file size is more than 6MB (6 * 1024 * 1024 bytes)
    if (file.size > 6 * 1024 * 1024) {
      toast.error("Please upload an image of less than 6MB.");
      return;
    }
  
    setShowInput(true);
    if (imageUrls.length >= 25) {
      toast.error("Maximum limit reached. You can upload up to 25 images.");
      return;
    }
  
    const folderPath = `${user.institution}`;
  
    setUploading(true);
    try {
      console.log("Uploading image:", file.name);
      await Storage.put(`${folderPath}/${file.name}`, file, {
        contentType: 'image/jpeg',
        bucket: 'team-dev-testing',
        region: 'us-east-2',
        ACL: 'public-read',
      });
      console.log("Image uploaded successfully.");
  
      const imageUrl = `https://${bucketName}.s3.${region}.amazonaws.com/public/${folderPath}/${file.name}`;
      setSelectedFile(imageUrl);
      console.log(title);
      console.log(description);
      console.log("API call successful.");
      getImagesFromAPI();
    } catch (error) {
      console.error('Error uploading image: ', error);
      toast.error('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };
  
  const uploadImageDataToAPI = async () => {
    const filename = selectedFile.split('/').pop();

    try {
      const data = {
        institution: institution,
        title: title,
        description: description,
        imageUrl: filename,
        imgLink: selectedFile,
      };

      await API.post('user', `/admin/upload-image/${institution}`, {
        body: data
      });
      setTitle("");
      setDescription("");
    } catch (error) {
      console.error('Error uploading image data to API: ', error);
    } finally {
      getImagesFromAPI();
      setTempImgSrc('');
    }
  };

  const getImagesFromAPI = async () => {
    try {
      const response = await API.get('user', `/user/get-imageUrl/${web.InstitutionId}`);
      console.log(response);
      setImageUrls(response.GalleryImagesLinks || []);
      if (tempImgSrc) {
        const filename = tempImgSrc.split('/').pop();
        if (response.gallery && response.gallery[filename]) {
          setDescription(response.gallery[filename].description);
          setTitle(response.gallery[filename].title);
        }
      }
    } catch (error) {
      console.error('Error getting images from API: ', error);
    }
  };

  useEffect(() => {
    console.log("Fetching images...");
    console.log("User data:", user);
    if (user && user.userType === 'admin') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    console.log("isAdmin after setting:", isAdmin);
    getImagesFromAPI();
    // eslint-disable-next-line 
  }, [user]);

  const getImg = (imageUrl) => {
    setTempImgSrc(imageUrl);
    setModel(true);
    setImg(imageUrl);
    getImagesFromAPI();
  };

  const handleDelete = async (tempImgSrc) => {
    console.log("Deleting image:", tempImgSrc);
    try {
      const key = img.split('https://team-dev-testing.s3.us-east-2.amazonaws.com/public/')[1];
      await Storage.remove(key, {
        bucket: 'team-dev-testing',
        region: 'us-east-2'
      });
      setImageUrls(prevUrls => prevUrls.filter(url => url !== tempImgSrc));
    } catch (error) {
      console.error('Error deleting image: ', error);
    }
    setReloadGallery(true);
    setModel(false);
  };

  const dataDelete = async (tempImgSrc) => {
    console.log(tempImgSrc);
    try {
      await API.del('user', `/admin/delete-image/${institution}`, {
        body: {
          imageUrl: tempImgSrc,
        }
      });
    } catch (error) {
      console.log(error);
    }
    handleDelete(tempImgSrc);
    setReloadGallery(true);
  };

  useEffect(() => {
    if (reloadGallery) {
      getImagesFromAPI();
      setReloadGallery(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadGallery]);

  return (
    <div>
    <div className='w-full h-screen overflow-hidden flex justify-center'>
      <NavBar />
      <div className='w-full h-screen overflow-hidden flex justify-center items-center mt-[4.5rem]'>
        <img className='absolute inset-0 w-full h-full object-cover' src={dancebg} alt="" />
        <div className='absolute inset-0' style={{ backdropFilter: 'blur(5px)' }}></div>
        <div className={model ? "model open" : "model"}>
          <div className="flex justify-between gap-3 max950:flex-col max950:items-center ">
            <div>
              <img src={tempImgSrc} alt="" />
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="white" className="close-btn w-10 h-10 z-[200]"
                onClick={() => {
                  setModel(false);
                  setTitle("");
                  setDescription('')
                }}>
                <path strokeLinecap="" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
              {isAdmin && (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="del-btn w-8 h-8 z-[200]" onClick={() => dataDelete(tempImgSrc)}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>
              )}
            </div>
            {title && description && (
              <div className="flex p-4 flex-col gap-4 justify-center z-[50] text-white w-[60rem] max950:w-[90vw]">
                <div className="text-[1.5rem] text-center border border-white bg-[#dbdbdb] text-black font-[700] uppercase">{title}</div>
                <div className='bg-[#3d3d3d62] p-3 text-[1.1rem] border border-white text-justify' style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backdropFilter: 'blur(5px)' }}>{description}</div>
              </div>
            )}
          </div>
        </div>
        {showInput &&
          <div className=' absolute flex flex-col justify-center items-center z-[200] w-[100vw] h-[100vh] bg-[#00000091]' style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0', backdropFilter: 'blur(5px)' }}>
            {uploading ? (
              <div className="w-[80vw] h-[35rem] flex flex-col justify-center items-center bg-[#ffffff6e] rounded-lg " style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backdropFilter: 'blur(5px)' }}>
                <Spinner />
              </div>
            ) : null}
            <img className='  max-w-[80vw] max-h-[34rem]' src={selectedFile} alt="" />
            <div className="flex gap-4 justify-center items-center mt-3 flex-col ">
              <div className='flex gap-4 justify-center items-center'>
                <input className='h-[3rem] p-[1rem] rounded-[8px] border border-white w-[35vw] bg-[#0000009d] text-[white]' type="text" placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
                <textarea className='scroolbar h-[6rem] p-[1rem] rounded-[8px] border border-white w-[35vw] bg-[#0000009d] text-[white]' placeholder="Description" onChange={(e) => setDescription(e.target.value)} />
              </div>
              <button className='w-[70vw] text-white font-bold p-2 bg-[#800060] rounded-[8px]' onClick={() => { setUploadingImage(true); setShowInput(false); uploadImageDataToAPI(selectedFile) }}>Upload</button>
            </div>
          </div>
        }
        <div className='scroolbar p-8 w-[95vw] h-[87vh] m-auto z-50'>
          {imageUrls.length === 0 && isAdmin && (
            <div className="w-[20rem] h-[14rem]">
              <label className="cursor-pointer flex text-black text-[1.2rem] rounded-lg font-semibold flex-col justify-center items-center w-[30rem] h-[14rem] bg-[#ffffff6e] mb-[2rem] max950:w-[30rem] max600:h-9 max600:rounded-none max600:flex-row" style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backdropFilter: 'blur(5px)' }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                </svg>
                Upload
                <input type="file" className="hidden" onChange={handleFileChange} />
              </label>
            </div>
          )}
          <div className=" max600:w-[84vw]">

            {/* <ReactSortable list={imageUrls} setList={setImageUrls}> */}
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4 }}
            >

              <Masonry>
                {imageUrls.length > 0 && isAdmin && (
                  <label className="upload cursor-pointer flex text-black text-[1.2rem] rounded-lg font-semibold flex-col justify-center items-center w-[20vw] h-[14rem] bg-[#ffffff6e] max950:w-[17rem] max950:h-[10rem] max600:h-9 max600:w-full max600:rounded-none max600:flex-row" style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backdropFilter: 'blur(5px)' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                    </svg>
                    Upload
                    <input type="file" className="hidden" onChange={handleFileChange} />
                  </label>
                )}
                {imageUrls.map((imageUrl, index) => {
                  return <img className='w-[25rem] p-1 object-cover' src={imageUrl} onClick={() => getImg(imageUrl)} alt="" />
                })}
              </Masonry>
            </ResponsiveMasonry>
            {/* </ReactSortable> */}
            {uploading ? (
              <div className="h-[14rem] flex flex-col justify-center items-center bg-[#ffffff6e] rounded-lg " style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backdropFilter: 'blur(5px)' }}>
                <Spinner />
              </div>
            ) : null}
          </div>

        </div>
      </div>
    </div >
    <Footer/>
    </div>
  );
};

export default Gallery;