import React, { useContext, useState, useEffect } from 'react';
import NavBar from '../Components/NavBar';
import InstitutionContext from '../Context/InstitutionContext';
import { API } from 'aws-amplify';
import './Schedule.css';
import Footer from '../Components/Home/Footer';
import Context from '../Context/Context';

const Schedule = () => {
  const [schedule, setSchedule] = useState({});
  const [commonTimeSlots, setCommonTimeSlots] = useState([]);
  const { institutionData } = useContext(InstitutionContext);
  const [loaderInitialized, setLoaderInitialized] = useState(false);
  const UtilCtx = useContext(Context).util;

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        if (!loaderInitialized) {
          UtilCtx.setLoader(true);
          setLoaderInitialized(true);
        }
  
        const currentDate = new Date();
        const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        const nextWeekStartDate = new Date(today);
        console.log(nextWeekStartDate);
        const nextWeekEndDate = new Date(today);
        nextWeekEndDate.setDate(nextWeekEndDate.getDate() + 7);
  
        const response = await API.get('user', `/user/schedule/${institutionData.InstitutionId}`);
  
        const nextWeekClasses = response.filter(classInfo => {
          const classDate = new Date(classInfo.startTime);
          return classDate >= today && classDate < nextWeekEndDate;
        });
  
        if (nextWeekClasses.length === 0) {
          setSchedule(null);
        } else {
          const groupedSchedule = groupScheduleByDay(nextWeekClasses);
          setSchedule(groupedSchedule);
          const commonTimes = extractCommonTimes(nextWeekClasses);
          setCommonTimeSlots(commonTimes);
          const timeSlotList = generateTimeSlotList(nextWeekClasses);
          console.log(timeSlotList);
          return timeSlotList;
        }
      } catch (error) {
        console.error('Error fetching schedule:', error);
      } finally {
        UtilCtx.setLoader(false);
      }
    };
  
    fetchSchedule();
    const groupScheduleByDay = (scheduleData) => {
      const groupedSchedule = {};
      scheduleData.forEach((classInfo) => {
        const date = new Date(classInfo.startTime);
        const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
        const startTime = formatTime(date);
        const track = classInfo.track || '';
        if (!groupedSchedule[dayOfWeek]) {
          groupedSchedule[dayOfWeek] = {};
        }
        if (!groupedSchedule[dayOfWeek][track]) {
          groupedSchedule[dayOfWeek][track] = [];
        }
        groupedSchedule[dayOfWeek][track].push({ ...classInfo, startTime });
      });
      return groupedSchedule;
    };
  }, [institutionData.InstitutionId, UtilCtx, loaderInitialized]);
  

 
  const [timeSlotList, setTimeSlotList] = useState({});



  const formatTime = (date) => {
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
  };

  const extractCommonTimes = (classes) => {
    const commonTimes = new Set();
    classes.forEach((classInfo) => {
      const date = new Date(classInfo.startTime);
      const startTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
      commonTimes.add(startTime);
    });
    return Array.from(commonTimes).sort();
  };

  const generateTimeSlotList = (nextWeekClasses) => {
    const timeSlotList = {};

    nextWeekClasses.forEach(classInfo => {
      const date = new Date(classInfo.startTime);
      const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
      const startTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

      if (!timeSlotList[startTime]) {
        timeSlotList[startTime] = {};
      }

      if (!timeSlotList[startTime][dayOfWeek]) {
        timeSlotList[startTime][dayOfWeek] = [];
      }


      timeSlotList[startTime][dayOfWeek].push(classInfo);
    });

    setTimeSlotList(timeSlotList); 

    return timeSlotList;
  };

  return (
    <div className="page-wrapper">
      <NavBar />
      <div className="container text-[#ffffff]">
        {schedule === null ? (
          <div className="text-[#000000] flex flex-row justify-center items-center w-full mt-24">
            <h1 className="font-bold">No classes scheduled for the next 7 days.</h1>
            <div className='h-24'></div>
          </div>
        ) : (
          <div className="schedule-container">
            <table style={{ backgroundColor: institutionData.PrimaryColor
, zIndex: 1000 }}>
              <thead>
                <tr>
                  <th></th>
                  {commonTimeSlots.map((timeSlot, idx) => (
                    <th key={idx} colSpan="2">{timeSlot}</th>
                  ))}
                </tr>
                <tr className='mob'>
                  <th></th> 
                  {commonTimeSlots.map((timeSlot, idx) => (
                    <React.Fragment key={idx}>
                      <th>Class Type</th>
                      <th>Instructor Name</th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
              
                {Object.keys(schedule).map((day, idx) => (
                  <tr key={idx}>
                    <td>{day}</td>
                    {commonTimeSlots.map((timeSlot, idx) => (
                      <td key={idx} colSpan="2">
                        {(timeSlotList[timeSlot] && timeSlotList[timeSlot][day]) ? (
                          <table>
                           <tbody className="no-border">
                              {timeSlotList[timeSlot][day].map((classInfo, index) => (
                                <tr key={index} className='cls'>
                                  <td>{classInfo.classType}</td>
                                  <td>{classInfo.instructorNames}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : <h1 className='text-[#ffffff]'>--</h1>
                        }
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className='h-10'></div>
      <Footer />
    </div>
  );
  
};

export default Schedule;